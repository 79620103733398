import { createVinByRegExpRemover } from "@oneaudi/audi-tracking-service";
import type { UrlSanitizerV2 } from "@oneaudi/audi-tracking-service";

import {
  RENDERER_PARTIAL_DIGITAL_DATA_COMPONENTS_ID,
  RENDERER_PARTIAL_DIGITAL_DATA_PAGE_ID,
  RENDERER_PARTIAL_DIGITAL_DATA_DEALERS_ID,
} from "@oneaudi/falcon-renderer-core/tracking";
import type {
  DigitalDataComponent,
  DigitalDataEvent,
  DigitalDataPage,
  DigitalDataDealer,
} from "@oneaudi/falcon-renderer-core/tracking";

const urlSanitizer: UrlSanitizerV2 = createVinByRegExpRemover();

declare global {
  interface Window {
    digitalData?: {
      page?: DigitalDataPage;
      component?: DigitalDataComponent[];
      event?: DigitalDataEvent[];
      dealer?: DigitalDataDealer[];
    };
  }
}

export function mergeDigitalDataPage(
  data1: DigitalDataPage,
  data2: DigitalDataPage
): DigitalDataPage {
  return {
    attributes: { ...data1.attributes, ...data2.attributes },
    category: { ...data1.category, ...data2.category },
    pageInfo: { ...data1.pageInfo, ...data2.pageInfo },
  };
}

function createDigitalDataAttributeFromHtml<T>(
  htmlTagId: string
): T | undefined {
  const digitalDataAttribute = document.getElementById(htmlTagId);

  if (digitalDataAttribute && digitalDataAttribute.textContent) {
    try {
      return JSON.parse(decodeURIComponent(digitalDataAttribute.textContent));
    } catch (err) {
      console.error(
        `An error occured while parsing the tracking data provided by the renderer found in the HTML tag with id ${htmlTagId}.`,
        err
      );
    }
  }

  return undefined;
}

export function performPageTracking(): void {
  // Initialize digitalData
  window.digitalData ||= {};
  window.digitalData.page ||= {};
  window.digitalData.event ||= [];

  const pageData = createDigitalDataAttributeFromHtml<DigitalDataPage>(
    RENDERER_PARTIAL_DIGITAL_DATA_PAGE_ID
  );

  if (pageData) {
    window.digitalData.page = mergeDigitalDataPage(
      window.digitalData.page,
      pageData || {}
    );
  }

  // Collect all frontend data for digitalData.page:
  const digitalDataPageFrontend: DigitalDataPage = {
    pageInfo: {
      destinationURL: urlSanitizer(window.location.href || ""),
      referringURL: urlSanitizer(document.referrer || ""),
    },
  };

  // Merge the frontend page data
  window.digitalData.page = mergeDigitalDataPage(
    window.digitalData.page,
    digitalDataPageFrontend
  );

  // Read the digitalData.component data from the renderer
  // Data is enriched by the components (the feature apps) itself.
  const componentsData = createDigitalDataAttributeFromHtml<
    DigitalDataComponent[]
  >(RENDERER_PARTIAL_DIGITAL_DATA_COMPONENTS_ID);

  if (componentsData) {
    window.digitalData.component = componentsData;
  }

  const dealersData = createDigitalDataAttributeFromHtml<DigitalDataDealer[]>(
    RENDERER_PARTIAL_DIGITAL_DATA_DEALERS_ID
  );

  if (dealersData) {
    window.digitalData.dealer = dealersData;
  }

  // Send the pageload event
  const dataLayerEvent: DigitalDataEvent = {
    eventInfo: {
      eventAction: "page_load",
      eventName: "generic",
    },
    attributes: {
      componentName: "",
      label: "",
      currentURL: window.location.href,
      targetURL: "",
      clickID: "",
      elementName: "",
      value: "",
      pos: "",
    },
  };
  window.digitalData.event?.push(dataLayerEvent);
}
